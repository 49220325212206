/**
 * Structured data component for
 * article markup - SEO. 
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function StructuredData({ 
    author, 
    logoUrl, 
    datePublished, 
    articleTitle, 
    articleDescription, 
    language, 
    articleUrl, 
    thumbnailUrl 
}) {

    const { site } = useStaticQuery(
        graphql`
          query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    siteUrl
                    logoUrl
                    language
                    organization
                }
            }
          }
        `
    )

    const authorString = author || site.siteMetadata.author;
    const organization = site.siteMetadata.organization;
    const logoImageUrl = logoUrl || site.siteMetadata.logoUrl;
    const languageString = language || site.siteMetadata.language;
    const postUrl = site.siteMetadata.siteUrl + articleUrl;

    const articleSchema = {
        "@context": "http://schema.org",
        "@type": "Article",
        author: {
            "@type": "Person",
            name: authorString,
        },
        copyrightHolder: {
            "@type": "Person",
            name: authorString,
        },
        copyrightYear: new Date().getFullYear().toString(),
        creator: {
            "@type": "Person",
            name: authorString,
        },
        publisher: {
            "@type": "Organization",
            name: organization,
            logo: {
            "@type": "ImageObject",
                url: logoImageUrl,
            },
        },
        datePublished: datePublished,
        dateModified: datePublished,
        description: articleDescription,
        headline: articleTitle,
        inLanguage: languageString,
        url: postUrl,
        name: articleTitle,
        image: {
            "@type": "ImageObject",
            url: thumbnailUrl,
        },
        mainEntityOfPage: postUrl,
    }

  return (
    <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(articleSchema)}
        </script>
    </Helmet>
  )
}

StructuredData.defaultProps = {
  author: '',
  logoUrl: '',
  language: ''
}

StructuredData.propTypes = {
  author: PropTypes.string,
  logoUrl: PropTypes.string,
  datePublished: PropTypes.string.isRequired,
  articleTitle: PropTypes.string.isRequired,
  articleDescription: PropTypes.string.isRequired,
  language: PropTypes.string,
  articleUrl: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired
}

export default StructuredData;
