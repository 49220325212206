import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Colors from "../constants/Colors"
import StructuredData from "../components/StructuredData"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const thumbnailUrl = post.frontmatter.featuredImage
      ? "https://blog.firma-navn.dk" +
        post.frontmatter.featuredImage.childImageSharp.fluid.src
      : "https://blog.firma-navn.dk/thumbnail.png"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <StructuredData
          datePublished={post.frontmatter.date}
          articleTitle={post.frontmatter.title}
          articleDescription={post.frontmatter.description}
          articleUrl={post.fields.slug}
          thumbnailUrl={thumbnailUrl}
        />
        <h1>{post.frontmatter.title}</h1>
        <p style={textStyling}> {post.frontmatter.date} </p>
        <div style={card} dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr style={{ marginBottom: rhythm(1), borderColor: Colors.white }} />
        <Bio />
        <ul style={listStyling}>
          <li>
            {previous && (
              <Link
                className="blog-bottom-link"
                to={previous.fields.slug}
                rel="prev"
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                className="blog-bottom-link"
                to={next.fields.slug}
                rel="next"
              >
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
          <li>
            <Link className="blog-bottom-link" to={"/firmanavn/"} rel="next">
              Se vores komplette guide til firmanavn ✅
            </Link>
          </li>
        </ul>
      </Layout>
    )
  }
}

const card = {
  backgroundColor: Colors.white,
  padding: 30,
  borderRadius: 15,
}

const textStyling = {
  display: `block`,
  marginBottom: rhythm(1),
  marginTop: rhythm(-1),
  color: Colors.white,
  opacity: 0.8,
}

const listStyling = {
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `space-between`,
  listStyle: `none`,
  padding: 5,
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD-MM-YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
